import {request} from '@/utils/axios'

// 技术规程-总数
export const technologyCount = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/web/TechnologyCount/list',
        params
    })
}
// 技术规程-产业政策
export const technologyPolicy = (params) => {
    return request({
        method: 'get',
        url: sysUrl+'/web/technologyPolicy/list',
        params
    })
}