<template>
    <div class="jsgc">
        <div class="wrap">
            <div class="top">
                <div class="info">
                    <ul class="info_list">
                        <li class="item">
                            <h3>知识总数<span>(条)</span></h3>
                            <ul class="num_list">
                                <li v-for="(item,i) in infoObj.knowledge.split('')" :key="i">{{item}}</li>
                            </ul>
                        </li>
                        <li class="item">
                            <h3>栽培技术<span>(条)</span></h3>
                            <ul class="num_list">
                                <li v-for="(item,i) in infoObj.cultivate.split('')" :key="i">{{item}}</li>
                            </ul>
                        </li>
                        <li class="item">
                            <h3>标准规程<span>(条)</span></h3>
                            <ul class="num_list">
                                <li v-for="(item,i) in infoObj.standard.split('')" :key="i">{{item}}</li>
                            </ul>
                        </li>
                        <li class="item">
                            <h3>产业政策<span>(条)</span></h3>
                            <ul class="num_list">
                                <li v-for="(item,i) in infoObj.policy.split('')" :key="i">{{item}}</li>
                            </ul>
                        </li>
                        <li class="item">
                            <h3>在线讲座<span>(个)</span></h3>
                            <ul class="num_list">
                                <li v-for="(item,i) in infoObj.lecture.split('')" :key="i">{{item}}</li>
                            </ul>
                        </li>
                        <li class="item">
                            <h3>累计阅读<span>(次)</span></h3>
                            <ul class="num_list">
                                <li v-for="(item,i) in infoObj.readnum.split('')" :key="i">{{item}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="nav">
                    <ul>
                        <li :class="{active:selectIndex==item.url}" @click="goTo(item.url)" v-for="item in navList" :key="item.title">{{item.title}}</li>
                    </ul>
                </div>
            </div>
            <div class="bottom">
                <keep-alive>
                    <component :is="curr" :jsType="selectIndex"></component>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import JsgcTable from './table'
import JsgcZxjz from './zxjz'
import * as api from '@/api/jsgc' //接口
export default {
    name:'Jsgc',
    components:{JsgcTable,JsgcZxjz},
    data(){
        return {
            curr:'JsgcTable',//当前组件
            selectIndex:'cyzc',//当前选择
            infoObj:{//信息对象
                knowledge:'0',//知识总数
                cultivate:'0',//栽培技术
                standard:'0',//标准规程
                policy:'0',//产业政策
                lecture:'0',//在线讲座
                readnum:'0',//累计阅读
            },
            navList:[
                {
                    title:'产业政策',
                    url:'cyzc',
                },
                {
                    title:'标准规程',
                    url:'bjgc',
                },
                {
                    title:'栽培技术',
                    url:'cpjs',
                },
                {
                    title:'加工处理',
                    url:'jgcl',
                },
                {
                    title:'在线讲座',
                    url:'zxjz',
                },
            ],
        }
    },
    mounted(){
        this.technologyCount()
    },
    methods:{
        technologyCount(){//总数
            api.technologyCount({}).then(res=>{
                // console.log('总数')
                // console.log(JSON.parse(JSON.stringify(res.data)))
                for (const key in this.infoObj) {
                    this.infoObj[key]=String(res.data[key])||0
                }
            })
        },
        goTo(url){//跳转
            this.selectIndex=url
            if (url=='zxjz') {//在线讲座
                this.curr='JsgcZxjz'
            }else if(url=='cyzc'){//产业政策
                this.curr='JsgcTable'
            }else{//其它
                this.curr='JsgcTable'
            }
        }
    }
}
</script>
<style lang='scss' scoped>
    .jsgc{
        width: 100%;
        height: 100%;
        // border: 3px solid #f00;
        box-sizing: border-box;
        overflow: hidden;
        // background: url('~@/assets/images/jsgc_bg.png') no-repeat center/100% 100%;
        .wrap{
            width: 1515px;
            // border: 3px solid #0f0;
            margin: 150px auto 0;
            .top{
                // background: rgba(1,48,96,0.92);
                // border: 2px solid #35B0FD;
                background: url('~@/assets/images/jsgc_top_bg.png') no-repeat center/100% 100%;
                height: 137px;
                .info{
                    background: url('~@/assets/images/jsgc_info_bg.png') no-repeat center/100% 100%;
                    width: 900px;
                    height: 180px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 90px;
                    // border: 3px solid #0f0;
                    position: relative;
                    bottom: 150px;
                    .info_list{
                        display: flex;
                        justify-content: space-around;
                        flex: 1;
                        .item{
                            >h3{
                                font-size: 22px;
                                font-weight: normal;
                                text-align: center;
                                span{
                                    font-size: 18px;
                                }
                            }
                            .num_list{
                                display: flex;
                                margin-top: 20px;
                                justify-content: center;
                                li{
                                    width: 34px;
                                    height: 46px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 34px;
                                    color: #FED471;
                                    font-weight: 700;
                                    margin: 0 2px;
                                    background: url('~@/assets/images/jsgc_num_bg.png') no-repeat center/100% 100%;
                                }
                            }
                        }
                    }
                }
                .nav{
                    // border: 1px solid #f00;
                    position: relative;
                    bottom: 120px;
                    ul{
                        display: flex;
                        justify-content: space-around;
                        padding: 0 180px;
                        box-sizing: border-box;
                        li{
                            width: 175px;
                            height: 51px;
                            font-size: 24px;
                            color: #32EEFF;
                            text-align: center;
                            line-height: 0;
                            background: url('~@/assets/images/gk_nav.png') no-repeat center/100% 100%;
                            cursor: pointer;
                        }
                        // li:nth-child(2){
                        //     margin: 0 45px;
                        // }
                        .active{
                            font-weight: bold;
                            color: #fff;
                            background: url('~@/assets/images/gk_nav_active.png') no-repeat center/100% 100%;
                        }
                    }
                }
            }
            .bottom{
                height: 520px;
                background: rgba(1,48,96,0.92);
                border: 2px solid #35B0FD;
                border-top: none;
                padding: 20px 60px;
                box-sizing: border-box;
            }
        }
    }
</style>