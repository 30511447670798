<template>
  <div class="jsgc_table">
    <div class="table_list">
      <el-table
        :data="tableData1"
        stripe
        class="pub_table mr30"
        height="100%"
        @row-click="selectRow"
      >
        <el-table-column prop="name" label="标题" min-width="100"></el-table-column>
        <!-- <el-table-column prop="type1" label="类别" width="150" align="center"></el-table-column> -->
        <el-table-column prop="screatetime" label="时间" width="180" align="center"></el-table-column>
      </el-table>
      <el-table :data="tableData2" stripe class="pub_table" height="100%" @row-click="selectRow">
        <el-table-column prop="name" label="标题" min-width="100"></el-table-column>
        <!-- <el-table-column prop="type1" label="类别" width="150" align="center"></el-table-column> -->
        <el-table-column prop="screatetime" label="时间" width="180" align="center"></el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pub_laypage"
      :page-size="15"
      @current-change="currChange"
      :current-page.sync="currPage"
      prev-text="上一页"
      next-text="下一页"
      :pager-count="11"
      layout="prev, pager, next,total"
      :total="total"
    ></el-pagination>
    <!-- 图文详情 -->
    <article-detail ref="articleDetail"></article-detail>
  </div>
</template>

<script>
import { knowbases } from '@/api/zjfw' //接口
import { technologyPolicy } from '@/api/jsgc' //接口
import ArticleDetail from '@/components/articleDetail' //图文详情
export default {
  components: { ArticleDetail },
  props: {
    jsType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData1: [
        //列表1
        // {
        //     date: '2016-05-02',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1518 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }, {
        //     date: '2016-05-04',
        //     name: '王小虎',
        //     address: '上海市普陀区金沙江路 1517 弄'
        // }
      ],
      tableData2: [], //列表2
      currPage: 1, //当前页
      pageSize: 15, //每页条数
      total: 0, //总数
      type: '', //类型
      articleFlag: false, //图文详情弹框
    }
  },
  watch: {
    jsType(val) {
      // console.log('变化了')
      // console.log(val)
      if (val == 'cyzc') {
        //产业政策
        this.technologyPolicy()
      } else {
        if (val == 'bjgc') {
          this.type = '规程'
        } else if (val == 'cpjs') {
          this.type = '技术'
        } else if (val == 'jgcl') {
          this.type = '加工'
        }
        this.knowbases()
      }
    },
  },
  mounted() {
    this.technologyPolicy()
  },
  methods: {
    knowbases(val) {
      //知识库
      this.currPage = val || 1
      knowbases({
        token: window.sessionStorage.token,
        name: this.type,
        type1: '蔬菜',
        page: this.currPage,
      })
        .then((res) => {
          // console.log('知识库')
          // console.log(JSON.parse(JSON.stringify(res.data.data)))
          let data = res.data.data
          this.tableData1 = data.slice(0, 8)
          this.tableData2 = data.slice(8)
          this.total = res.data.count
        })
        .catch(() => {
          this.tableData1 = []
          this.tableData2 = []
          this.total = []
        })
    },
    technologyPolicy(val) {
      //产业政策
      this.currPage = val || 1
      technologyPolicy({
        pageSize: this.pageSize,
        pageNum: this.currPage,
      })
        .then((res) => {
          // console.log('产业政策')
          // console.log(JSON.parse(JSON.stringify(res.rows)))
          let data = res.rows
          this.tableData1 = data.slice(0, 8)
          this.tableData2 = data.slice(8)
          this.total = res.total
        })
        .catch(() => {
          this.tableData1 = []
          this.tableData2 = []
          this.total = []
        })
    },
    currChange(val) {
      //页码切换
      // console.log('页码切换')
      // console.log(val)
      this.knowbases(val)
    },
    selectRow(row, column, event) {
      //选中行
      // console.log('选中行')
      // console.log(JSON.parse(JSON.stringify(row)))
      let obj = {
        title: row.name,
        createtime: row.screatetime,
        content: row.content,
      }
      this.$refs.articleDetail.show(obj)
    },
  },
}
</script>

<style lang='scss' scoped>
.jsgc_table {
  height: 100%;
  display: flex;
  flex-direction: column;
  .mr30 {
    margin-right: 30px;
  }
  .table_list {
    display: flex;
    flex: 1;
    overflow: hidden;
    margin-bottom: 10px;
  }
  ::v-deep.pub_table {
    tr {
      cursor: pointer;
    }
  }
}
</style>