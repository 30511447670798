<template>
    <div class="zxjz">
        <ul class="list" v-infinite-scroll="reLoad" infinite-scroll-disabled="disabled" infinite-scroll-distance="10">
            <li v-for="(item,i) in listData" :key="i">
                <div class="wrap">
                    <div class="video">
                        <video controls>
                            <source :src="wjImageUrl+item.videourl" type="video/mp4">
                            您的浏览器不支持Video标签。
                        </video>
                    </div>
                    <div class="content">
                        <p @click="lookVideo(item)">{{item.name}}</p>
                        <span><i>主讲人：</i>{{item.username}}</span>
                    </div>
                </div>
            </li>
        </ul>
        <p v-if="loading">加载中...</p>
        <p v-if="noMore">没有更多了</p>
        <!-- 视频详情 -->
        <video-detail ref="videoDetail"></video-detail>
    </div>
</template>
<script>
import {videos} from '@/api/zjfw' //接口
// import { wjImageUrl } from '@/utils/config'
import VideoDetail from '@/components/videoDetail' //视频详情
export default {
    components:{VideoDetail},
    data(){
        return {
            listData:[
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术食用菌保鲜加功技术',
                //     xm:'王立安',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立安王立',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立安',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立安安',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立安',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立安',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立安',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立安',
                // },
                // {
                //     url:'',
                //     title:'食用菌保鲜加功技术',
                //     xm:'王立安',
                // },
            ],
            currPage:1,//当前页
            totalPage: "",
            loading: false,
            wjImageUrl,
        }
    },
    computed: {
        noMore() {
            // 当起始页数大于等于总页数时停止加载
            return this.currPage >= this.totalPage;
        },
        disabled() {
            return this.loading || this.noMore;
        }
    },
    mounted(){
        // console.log('在线讲座初始化')
        this.videos()
    },
    methods:{
        videos(){//视频讲座
            videos({
                token: window.sessionStorage.token,
                type1:'蔬菜',
                page:this.currPage,
            }).then(res=>{
                // console.log('视频讲座')
                // console.log(JSON.parse(JSON.stringify(res.data.data)))
                this.totalPage=res.data.totalPages
                this.listData=[...this.listData,...res.data.data]
                this.loading = false;
            }).catch(()=>{
                this.loading = false;
            })
        },
        reLoad(){//知识库无限加载
            // console.log('知识库无限加载')
            //滑到底部时进行加载
            this.loading = true;
            setTimeout(() => {
                this.currPage += 1; //滚动条到底时，页码自增 1
                this.videos(); //调用接口
            }, 500);
        },
        lookVideo(v){//查看视频
            // console.log('查看视频')
            // console.log(JSON.parse(JSON.stringify(v)))
            let obj={
                title:v.name,//标题
                username:v.username,//讲师
                hits:v.hits,//播放量
                videosource:v.videosource,//单位
                screatetime:v.screatetime,//时间
                coursedesc:v.coursedesc,//课程介绍
                coursesyllabus:v.coursesyllabus,//课程大纲
                videourl:v.videourl,//视频
            }
            this.$refs.videoDetail.show(obj)
        }
    }
}
</script>
<style lang='scss' scoped>
    .zxjz{
        // border: 2px solid #f00;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .list{
            display: flex;
            flex-wrap: wrap;
            margin: -20px -20px 0;
            flex: 1;
            overflow-y: auto;
            >li{
                // border: 1px solid #0f0;
                box-sizing: border-box;
                width: 25%;
                margin-top: 20px;
                .wrap{
                    width: 320px;
                    margin: 0 auto;
                    .video{
                        width: 100%;
                        height: 190px;
                        padding: 5px;
                        box-sizing: border-box;
                        background: url('~@/assets/images/zxjz_video_border.png') no-repeat center/100% 100%;
                        video{
                            width: 100%;
                            height: 100%;
                            // border: 1px solid #0f0;
                            // box-sizing: border-box;
                        }
                    }
                    .content{
                        font-size: 16px;
                        height: 35px;
                        padding: 0 10px;
                        margin-top: 10px;
                        box-sizing: border-box;
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        background: url('~@/assets/images/zxjz_text_bg.png') no-repeat center/100% 100%;
                        p{
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                            // border: 1px solid #0f0;
                            flex: 1;
                            margin-right: 10px;
                            cursor: pointer;
                        }
                        span{
                            flex: none;
                            // border: 1px solid #f00;
                            i{
                                color: #02CAFD;
                            }
                        }
                    }
                }
            }
        }
        p{
            font-size: 16px;
            text-align: center;
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
</style>